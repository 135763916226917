import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  smallTitle?: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path: string;
  title: string;
  smallTitle?: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems3[];
  isCollapsed?: boolean;
}
export interface ChildrenItems3 {
  path: string;
  title: string;
  smallTitle?: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems4[];
  isCollapsed?: boolean;
}

export interface ChildrenItems4 {
  path?: string;
  smallTitle?: string;
  title?: string;
  type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "tele",
    title: "Dashboard",
    type: "sub",
    icontype: "tim-icons icon-chart-pie-36",
    collapse: "dashboard",
    isCollapsed: true,
    children: [
      {
        path: "genie-dashboard",
        title: "Home",
        type: "link",
        smallTitle: "GD",
      },
      {
        path: "total",
        title: "Summary",
        type: "link",
        smallTitle: "SY",
      },
      {
        path: "monthly-vital-few",
        title: "Monthly Vital Few",
        type: "link",
        smallTitle: "MVF",
      },
    ],
  },
  {
    path: "",
    title: "Genie",
    type: "sub",
    icontype: "tim-icons icon-atom",
    collapse: "genie",
    isCollapsed: true,
    children: [
      {
        path: "genie-insights",
        title: "Genie Insight",
        type: "link",
        smallTitle: "GI",
      },
      {
        path: "user-insights",
        title: "User Insights",
        type: "link",
        smallTitle: "UI",
      },
    ],
  },
  {
    path: "",
    title: "Contract",
    type: "sub",
    icontype: "tim-icons icon-notes",
    collapse: "contract",
    isCollapsed: true,
    children: [
      {
        path: "contract-synopsis",
        title: "Synopsis",
        type: "link",
        smallTitle: "SY",
      },
      {
        path: "contract-validation",
        title: "Validation",
        type: "link",
        smallTitle: "VA",
      },
    ],
  },
];

export const ROUTES_USER: RouteInfo[] = [
  {
    path: "",
    title: "Admin",
    type: "sub",
    icontype: "tim-icons icon-support-17",
    collapse: "admin",
    isCollapsed: true,
    children: [
      {
        path: "uploads",
        title: "File Upload",
        type: "link",
        smallTitle: "FU",
      },
    ],
  },
];

export const ROUTES_ADMIN: RouteInfo[] = [
  {
    path: "",
    title: "Admin",
    type: "sub",
    icontype: "tim-icons icon-support-17",
    collapse: "admin",
    isCollapsed: true,
    children: [
      {
        path: "uploads",
        title: "File Upload",
        type: "link",
        smallTitle: "FU",
      },
      {
        path: "user-plans",
        title: "User Plans",
        type: "link",
        smallTitle: "UP",
      },
      {
        path: "contracts",
        title: "Contracts",
        type: "link",
        smallTitle: "CP",
      },
      {
        path: "market-plans",
        title: "Market Plans",
        type: "link",
        smallTitle: "MP",
      },
      {
        path: "bug-report",
        title: "Bug Report",
        type: "link",
        smallTitle: "BR",
      },
    ],
  },
];

export const FLEET_ROUTES: RouteInfo[] = [
  {
    path: "fleet",
    title: "Dashboard",
    type: "sub",
    icontype: "tim-icons icon-chart-pie-36",
    collapse: "dashboard",
    isCollapsed: true,
    children: [
      {
        path: "total",
        title: "Summary",
        type: "link",
        smallTitle: "SY",
      },
      {
        path: "monthly-vital-few",
        title: "Monthly Vital Few",
        type: "link",
        smallTitle: "MVF",
      },
    ],
  },
  {
    path: "",
    title: "Genie",
    type: "sub",
    icontype: "tim-icons icon-atom",
    collapse: "genie",
    isCollapsed: true,
    children: [
      {
        path: "genie-insights",
        title: "Genie Insight",
        type: "link",
        smallTitle: "GI",
      },
      {
        path: "vehicle-insights",
        title: "Vehicle Insights",
        type: "link",
        smallTitle: "VI",
      },
    ],
  },
];

export const FLEET_ROUTES_USER: RouteInfo[] = [
  {
    path: "",
    title: "Admin",
    type: "sub",
    icontype: "tim-icons icon-support-17",
    collapse: "admin",
    isCollapsed: true,
    children: [
      {
        path: "uploads",
        title: "File Upload",
        type: "link",
        smallTitle: "FU",
      },
    ],
  },
];

export const FLEET_ROUTES_ADMIN: RouteInfo[] = [
  {
    path: "",
    title: "Admin",
    type: "sub",
    icontype: "tim-icons icon-support-17",
    collapse: "admin",
    isCollapsed: true,
    children: [
      {
        path: "uploads",
        title: "File Upload",
        type: "link",
        smallTitle: "FU",
      },
      // {
      //   path: "user-plans",
      //   title: "Vehicles",
      //   type: "link",
      //   smallTitle: "VP",
      // },
      // {
      //   path: "contracts",
      //   title: "Contracts",
      //   type: "link",
      //   smallTitle: "CP",
      // },
      // {
      //   path: "market-plans",
      //   title: "Department Budgets",
      //   type: "link",
      //   smallTitle: "DB",
      // },
      // {
      //   path: "bug-report",
      //   title: "Bug Report",
      //   type: "link",
      //   smallTitle: "BR",
      // },
    ],
  },
];

export const CLOUD_ROUTES: RouteInfo[] = [
  {
    path: "dashboard",
    title: "Dashboard",
    type: "link",
    icontype: "tim-icons icon-chart-pie-36",
  },
  {
    path: "monthly-vital-few",
    title: "Monthly Vital Few",
    type: "link",
    icontype: "tim-icons icon-coins",
  },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  public isAdmin: boolean = false;
  menuItems: any[];
  location: Location;

  constructor(location: Location) {
    this.location = location;
  }

  ngOnInit() {
    if (this.location.path().toLowerCase().includes("tele")) {
      this.isAdmin = JSON.parse(sessionStorage.getItem("user")).isAdmin;
      if (this.isAdmin)
        this.menuItems = [
          ...ROUTES.filter((menuItem) => menuItem),
          ...ROUTES_ADMIN.filter((menuItem) => menuItem),
        ].map((item) => {
          item.path = "tele";
          return item;
        });
      else {
        this.menuItems = [
          ...ROUTES.filter((menuItem) => menuItem),
          ...ROUTES_USER.filter((menuItem) => menuItem),
        ].map((item) => {
          item.path = "tele";
          return item;
        });
      }
    } else if (this.location.path().toLowerCase().includes("fleet")) {
      // this.isAdmin = JSON.parse(sessionStorage.getItem("user")).isAdmin;
      this.isAdmin = true;
      if (this.isAdmin) {
        this.menuItems = [
          ...FLEET_ROUTES.filter((menuItem) => menuItem),
          ...FLEET_ROUTES_ADMIN.filter((menuItem) => menuItem),
        ].map((item) => {
          item.path = "fleet";
          return item;
        });
      } else {
        this.menuItems = [
          ...FLEET_ROUTES.filter((menuItem) => menuItem),
          ...FLEET_ROUTES_USER.filter((menuItem) => menuItem),
        ].map((item) => {
          item.path = "fleet";
          return item;
        });
      }
    } else this.menuItems = CLOUD_ROUTES.filter((menuItem) => menuItem);
  }
}
