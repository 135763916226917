import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { TeleLayoutComponent } from "./layouts/tele-layout/tele-layout.component";
import { AuthGuard } from "./telephone/auth/auth.guard";
import { AuthService } from "./telephone/auth/auth.service";
import { LoginComponent } from "./telephone/pages/login/login.component";
import { CloudLayoutComponent } from "./layouts/cloud-layout/cloud-layout.component";
import { FleetLayoutComponent } from "./layouts/fleet-layout/fleet-layout.component";

const routes: Routes = [
  {
    path: "fleet",
    children: [
      {
        path: "",
        component: LoginComponent,
        loadChildren: () =>
          import("src/app/fleet/pages/login/login.module").then(
            (m) => m.LoginModule
          ),
      },
      {
        path: "",
        component: FleetLayoutComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            loadChildren:
              "./layouts/fleet-layout/fleet-layout.module#FleetLayoutModule",
          },
        ],
      },
    ],
  },
  {
    path: "tele",
    children: [
      {
        path: "",
        component: LoginComponent,
        loadChildren: () =>
          import("src/app/telephone/pages/login/login.module").then(
            (m) => m.LoginModule
          ),
      },
      {
        path: "",
        component: TeleLayoutComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            loadChildren:
              "./layouts/tele-layout/tele-layout.module#TeleLayoutModule",
          },
        ],
      },
    ],
  },
  {
    path: "cloud",
    children: [
      {
        path: "",
        component: CloudLayoutComponent,
        children: [
          {
            path: "",
            loadChildren:
              "./layouts/cloud-layout/cloud-layout.module#CloudLayoutModule",
          },
        ],
      },
    ],
  },
  {
    path: "",
    redirectTo: "/tele",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
  providers: [AuthService, AuthGuard],
})
export class AppRoutingModule {}
