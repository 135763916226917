import { Component } from "@angular/core";

import { Location } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/telephone/auth/auth.service";
import { CustomerModel } from "src/app/telephone/store/customer/customer.model";
import { CustomerService } from "src/app/telephone/store/customer/customer.service";
import { Router } from "@angular/router";
import { AppRoutingModule } from "src/app/app-routing.module";
import { showNotification } from "src/app/utils/alert";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent {
  customer$: CustomerModel;
  location: Location;

  title = "";
  user: string;
  public isFleet: any;
  public isCollapsed = true;
  private dataArray: any = [];
  constructor(
    private router: Router,
    location: Location,
    public toastr: ToastrService,
    private customerService: CustomerService,
    public auth: AuthService,
    public appRouting: AppRoutingModule
  ) {
    this.location = location;
  }

  ngOnInit() {
    this.customerService.getCustomer().then((customer) => {
      this.customer$ = customer;
      this.isFleet = this.getIfFleet();
    });
    if (this.location.path().toLowerCase().includes("tele")) {
      this.title = "TeleGenie";
      this.customerService
        .getCustomer()
        .then((x: { company: string }) => (this.user = x.company));
    } else if (this.location.path().toLowerCase().includes("fleet")) {
      this.title = "FleetGenie";
      this.customerService
        .getCustomer()
        .then((x: { company: string }) => (this.user = x.company));
    } else {
      this.title = "CloudGenie";
      this.user = "Opex Genie";
    }
  }
  goto() {
    this.router.navigateByUrl("/tele/genie-dashboard");
  }

  gotoRoute(route: string) {
    this.router.navigateByUrl(route);
  }

  signOut() {
    this.auth.signOut();
  }
  getIfFleet(): void {
    this.customerService.getIfFleet(this.customer$).subscribe({
      next: (res) => {
        this.dataArray = res;
        this.isFleet = this.dataArray.data;
      },
      error: (error) => {
        const errorMessage = error.message
          ? error.message
          : "Oops, something went wrong. Please try again later.";

        showNotification(this.toastr, errorMessage, "error");
      },
      complete: () => {},
    });
  }
}
