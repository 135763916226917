import { Injectable } from "@angular/core";
import { ConfigService } from "./config-service";
import Amplify from "aws-amplify";

@Injectable({ providedIn: "root" })
export class EnvService {
  constructor(private configService: ConfigService) {}

  init(): Promise<void> {
    return new Promise((resolve) => {
      this.setEnvVariables();
      resolve();
    });
  }

  private writeintoFile(value): void {
    var testData = {
      aws_project_region: "us-east-2",
      aws_cognito_region: "us-east-2",
      aws_user_pools_id: value.aws_user_pools_id,
      aws_user_pools_web_client_id: value.aws_user_pools_web_client_id,
      oauth: {},
      aws_cognito_login_mechanisms: ["EMAIL", "PHONE_NUMBER"],
      aws_cognito_signup_attributes: ["EMAIL"],
      aws_cognito_mfa_configuration: "OFF",
      aws_cognito_mfa_types: ["SMS"],
      aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: 8,
        passwordPolicyCharacters: [],
      },
      aws_cognito_verification_mechanisms: ["EMAIL"],
      aws_cloud_logic_custom: [
        {
          name: "AdminQueries",
          endpoint:
            "https://z303rdqtii.execute-api.us-east-2.amazonaws.com/dev",
          region: "us-east-2",
        },
      ],
    };
    //    console.log("Configured");
    Amplify.configure(testData);
  }

  private callApi(): void {
    this.configService.getConfig().subscribe({
      next: (response: any) => {
        if (response) {
          // console.log('-----------------success ----------------->', response.aws_user_pools_id);
          this.writeintoFile(response);
        }
      },
      error: (error) => {
        const errorMessage = error.message
          ? error.message
          : "Oops, something went wrong. Please try again later.";
        console.error("There was an error!", error);
      },
    });
  }

  private setEnvVariables(): void {
    console.log("Setting up the identity configuration");
    this.callApi();
    console.log("Identity provider configured");
  }
}
