import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { AuthService } from "../auth/auth.service";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(public auth: AuthService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.includes("/aws/config") ||
      request.url.includes("/cost") ||
      request.url.includes("X-Amz-Algorithm")
    ) {
      next.handle(request);
    } else if (this.auth.isAuthenticated) {
      request = request.clone({
        headers: request.headers.set(
          "Authorization",
          "Bearer " + this.auth.token
        ),
      });
    } else {
      if(environment.production){
      this.auth.signOut();}
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if ([401, 403].includes(err.status) && environment.production) {
          this.auth.signOut();
        }

        const msg = (err && err.error && err.error.message) || err.statusText;
        const error = msg
          ? msg
          : "Oops, something went wrong. Please try again later.";

        return throwError(error);
      })
    );
  }
}
