import { Component, ChangeDetectorRef, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import {
  onAuthUIStateChange,
  CognitoUserInterface,
  AuthState,
} from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
@Component({
  selector: "app-login",
  templateUrl: "login.component.html",
})
export class LoginComponent {
  user: CognitoUserInterface | undefined;
  authState: AuthState;

  constructor(
    private ref: ChangeDetectorRef,
    private router: Router,
    private toastr: ToastrService,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    onAuthUIStateChange(async (authState, authData) => {
      this.authState = authState;
      this.user = authData as CognitoUserInterface;
      this.ref.detectChanges();

      if (this.user) {
        var tokens = this.user.signInUserSession;
        if (tokens != null) {
          const attributes = this.user.attributes;
          const groups = tokens.accessToken.payload["cognito:groups"];
          if (groups) {
            const isAdmin = groups.includes("oga");
            if (isAdmin) {
              const index = groups.indexOf("oga");
              if (index > -1) {
                groups.splice(index, 1);
              }
            }
            const company = {
              isAdmin,
              group: groups[0],
            };
            const userDetails = { ...attributes, ...company };
            localStorage.setItem("jwtToken", tokens.accessToken.jwtToken);
            sessionStorage.setItem("user", JSON.stringify(userDetails));
            this.ngZone.run(() =>
              this.router.navigate(["tele/genie-dashboard"])
            );
          } else {
            await Auth.signOut().finally(() => {
              if (this.authState == AuthState.SignIn) {
                this.showNotification(
                  "Please check if the user is assigned to a group."
                );
              }
            });
          }
        }
      }
    });
  }

  ngOnDestroy() {
    return onAuthUIStateChange;
  }

  showNotification(text: string) {
    this.toastr.error(
      `<span class="tim-icons icon-alert-circle-exc" [data-notify]="icon"></span> ${text}`,
      "",
      {
        disableTimeOut: false,
        closeButton: true,
        enableHtml: true,
        toastClass: "alert alert-primary alert-with-icon",
        positionClass: "toast-bottom-center",
        progressBar: true,
        progressAnimation: "decreasing",
      }
    );
  }
}
