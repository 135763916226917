import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Auth } from "aws-amplify";

const jwtHelper = new JwtHelperService();
@Injectable()
export class AuthService {
  token: string;

  constructor(private router: Router, private ngZone: NgZone) {}

  public isAuthenticated(): boolean {
    this.token = localStorage.getItem("jwtToken") ?? null;

    if (this.token == null) return false;

    return !jwtHelper.isTokenExpired(this.token);
  }

  async signOut() {
    try {
      await Auth.signOut().then((res) => {
        sessionStorage.clear();
        localStorage.clear();
        this.ngZone.run(() => this.router.navigate([""]));
      });
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }
}
