import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { ToastrModule } from "ngx-toastr";

import { AppComponent } from "./app.component";
import { TeleLayoutComponent } from "./layouts/tele-layout/tele-layout.component";
import { AppRoutingModule } from "./app-routing.module";
import { ComponentsModule } from "./components/components.module";
import { HttpClientModule } from "@angular/common/http";
import { AmplifyUIAngularModule } from "@aws-amplify/ui-angular";
import { EnvService } from "./env-service";
import { ReactiveFormsModule } from "@angular/forms";
import { CloudLayoutComponent } from "./layouts/cloud-layout/cloud-layout.component";
import { FleetLayoutComponent } from './layouts/fleet-layout/fleet-layout.component';

@NgModule({
  declarations: [AppComponent, TeleLayoutComponent, CloudLayoutComponent, FleetLayoutComponent],
  imports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      tapToDismiss: true,
      closeButton: true,
      preventDuplicates: true,
      disableTimeOut: true,
    }),
    ComponentsModule,
    AmplifyUIAngularModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (envService: EnvService) => () => {
        envService.init();
      },
      deps: [EnvService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
