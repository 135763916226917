import { ToastrService } from "ngx-toastr";

export const showNotification = (
  toastr: ToastrService,
  text: string,
  type: string
) => {
  switch (type) {
    case "error":
      toastr.error(
        `<span class="tim-icons icon-alert-circle-exc" [data-notify]="icon"></span> ${text}`,
        "",
        {
          disableTimeOut: false,
          closeButton: true,
          enableHtml: true,
          toastClass: "alert alert-primary alert-with-icon",
          positionClass: "toast-bottom-center",
          progressBar: true,
          progressAnimation: "decreasing",
        }
      );
      break;

    case "success":
      toastr.success(
        `<span class=" tim-icons icon-bell-55"></span> ${text}`,
        "",
        {
          disableTimeOut: false,
          closeButton: true,
          enableHtml: true,
          toastClass: "alert alert-success alert-with-icon",
          positionClass: "toast-bottom-center",
          progressBar: true,
          progressAnimation: "decreasing",
        }
      );
      break;

    case "info":
      toastr.info(`<span class=" tim-icons icon-bell-55"></span> ${text}`, "", {
        disableTimeOut: false,
        closeButton: true,
        enableHtml: true,
        toastClass: "alert alert-info alert-with-icon",
        positionClass: "toast-bottom-center",
        progressBar: true,
        progressAnimation: "decreasing",
      });
      break;

    default:
      break;
  }
};
