import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class ConfigService {
  constructor(private httpClient: HttpClient) {}

  private handleError(error: HttpErrorResponse) {
    return throwError("An error occurred:", error.error);
  }

  getConfig() {
    const url = `/api/aws/config`;

    return this.httpClient.get(url).pipe(retry(2), catchError(this.handleError));
  }
}